"use client";

import classNames from "classnames/bind";
import styles from "./AssistantTeaser.module.css";
import { CosmosButton, CosmosText, CosmosTitle } from "@cosmos/web/react";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { getImageSource } from "../../../helpers/getImageSource";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { getStoryblokImageUrl } from "../../../helpers/images/getStoryblokImageUrl";
import type { TeaserBasicProps } from "../../../components/TeaserBasic/TeaserBasic";
import { ButtonWrapper } from "../../../components/ButtonWrapper/ButtonWrapper";
import { Kicker } from "../../kicker/kicker";
import { analyticsClick } from "../../../helpers/analytics";
import { getStoryblokImageDimension } from "../../../helpers/images/getStoryblokImageDimension";

const cx = classNames.bind(styles);

export const AssistantTeaser = ({
  title,
  kicker,
  text,
  graphicUrl,
  graphicAlt = "",
  buttonLabel,
  buttonUrl,
}: {
  title: TeaserBasicProps["title"];
  kicker: TeaserBasicProps["kicker"];
  text: TeaserBasicProps["text"];
  graphicUrl: string;
  graphicAlt?: string;
  buttonLabel?: string;
  buttonUrl?: string;
}) => {
  const imageSource = graphicUrl ? getImageSource(graphicUrl) : null;
  const imageDimensions =
    imageSource === "storyblok"
      ? getStoryblokImageDimension(graphicUrl)
      : undefined;
  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("content")}>
          {kicker && (
            <Kicker kind="bare" text={kicker} className={cx("kicker")} />
          )}
          {title && (
            <CosmosTitle tag="h2" size="x-large" className={cx("heading")}>
              {title}
            </CosmosTitle>
          )}
          {text && (
            <div className={cx("text")}>
              <CosmosText appearance="dark" kind="normal" size="medium" tag="p">
                {text}
              </CosmosText>
            </div>
          )}

          {buttonLabel && buttonUrl && (
            <ButtonWrapper>
              <CosmosButton
                size="large"
                href={buttonUrl}
                className={cx("button")}
                onClick={() => {
                  analyticsClick({
                    value: "Ask Your Question",
                    clickUrl: buttonUrl,
                    elementPosition: "Contact",
                    method: "Outbound: FALSE",
                    contentType: "CTA",
                  });
                }}
              >
                {buttonLabel}
              </CosmosButton>
            </ButtonWrapper>
          )}
        </div>
        {graphicUrl &&
          (() => {
            const imageProps = {
              loading: imageLoading,
              className: cx("image"),
              alt: graphicAlt,
              width: imageDimensions?.width,
              height: imageDimensions?.height,
            };

            switch (imageSource) {
              case "contentful": {
                return (
                  <img
                    {...imageProps}
                    src={getContentfulImageUrl(graphicUrl, {
                      width: 352,
                      height: undefined,
                    })}
                  />
                );
              }
              case "storyblok": {
                return (
                  <img
                    {...imageProps}
                    src={getStoryblokImageUrl(graphicUrl, {
                      width: 352,
                      height: undefined,
                    })}
                  />
                );
              }
              default: {
                return <img {...imageProps} src={graphicUrl} />;
              }
            }
          })()}
      </div>
    </div>
  );
};
