"use client";

import { CosmosButton, CosmosIconChevronRight } from "@cosmos/web/react";
import {
  TeaserBasic,
  type TeaserBasicProps,
} from "../../../components/TeaserBasic/TeaserBasic";
import { ButtonWrapper } from "../../ButtonWrapper/ButtonWrapper";
import { getImageSource } from "../../../helpers/getImageSource";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { getStoryblokImageUrl } from "../../../helpers/images/getStoryblokImageUrl";
import styles from "./SustainabilityTeaser.module.css";
import classNames from "classnames/bind";
import { useMediaQuery } from "../../../helpers/useMediaQuery";
import { getStoryblokImageDimension } from "../../../helpers/images/getStoryblokImageDimension";

const cx = classNames.bind(styles);

export const SustainabilityTeaser = ({
  kicker,
  title,
  text,
  graphicUrl,
  graphicAlt = "",
  primaryButtonLabel,
  primaryButtonUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,
}: {
  kicker: TeaserBasicProps["title"];
  title: TeaserBasicProps["kicker"];
  text: TeaserBasicProps["text"];
  graphicUrl: string;
  graphicAlt?: string;
  primaryButtonLabel: string;
  primaryButtonUrl: string;
  secondaryButtonLabel: string;
  secondaryButtonUrl: string;
}) => {
  const imageSource = graphicUrl ? getImageSource(graphicUrl) : null;
  const imageDimensions =
    imageSource === "storyblok"
      ? getStoryblokImageDimension(graphicUrl)
      : undefined;

  const imageLoading = useImageLoading();

  const isDesktop = useMediaQuery("(width >= 1000px)", {
    initializeWithValue: false,
  });

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <TeaserBasic
            kicker={kicker}
            title={title}
            titleSize="xx-large"
            text={text}
            appearance={"dark"}
            headingElement={"kicker"}
          >
            <ButtonWrapper className={cx("cta__wrapper")}>
              <CosmosButton size="large" href={primaryButtonUrl}>
                {primaryButtonLabel}
              </CosmosButton>
              <CosmosButton
                href={secondaryButtonUrl}
                kind={isDesktop ? "secondary" : "link"}
                size="large"
                iconPlacement="after"
              >
                {!isDesktop && (
                  <CosmosIconChevronRight
                    slot="icon"
                    className={cx("secondary-cta__icon")}
                  />
                )}
                {secondaryButtonLabel}
              </CosmosButton>
            </ButtonWrapper>
          </TeaserBasic>
        </div>
        <div className={cx("image-wrap")}>
          {graphicUrl &&
            (() => {
              const imageProps = {
                loading: imageLoading,
                className: cx("img"),
                alt: graphicAlt,
                width: imageDimensions?.width,
                height: imageDimensions?.height,
              };

              switch (imageSource) {
                case "contentful": {
                  return (
                    <img
                      {...imageProps}
                      src={getContentfulImageUrl(graphicUrl, {
                        width: 570,
                        height: undefined,
                      })}
                    />
                  );
                }
                case "storyblok": {
                  return (
                    <img
                      {...imageProps}
                      src={getStoryblokImageUrl(graphicUrl, {
                        width: 570,
                        height: undefined,
                      })}
                    />
                  );
                }
                default: {
                  return <img {...imageProps} src={graphicUrl} />;
                }
              }
            })()}
        </div>
      </div>
    </div>
  );
};
