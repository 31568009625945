"use client";

import classNames from "classnames/bind";
import styles from "./ProductStageCards.module.css";
import { CosmosText } from "@cosmos/web/react";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { getImageSource } from "../../../helpers/getImageSource";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { getStoryblokImageUrl } from "../../../helpers/images/getStoryblokImageUrl";
import type { TeaserBasicProps } from "../../../components/TeaserBasic/TeaserBasic";
import { ButtonWrapper } from "../../../components/ButtonWrapper/ButtonWrapper";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { TeaserBasic } from "../../../components/TeaserBasic/TeaserBasic";
import { ProductRail } from "../ProductRail/ProductRail";
// import { ProductStageCardOverlay } from "./ProductStageCardOverlay";
import { getSchemeById } from "../../../helpers/colorSchemes";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";
import template from "../../../helpers/template";
import { getStoryblokImageDimension } from "../../../helpers/images/getStoryblokImageDimension";
// import { getStoryblokLinkUrl } from "../../../helpers/getStoryblokLinkUrl";
import { ProductRailContext } from "../../../helpers/ProductRailContext";
import { useState } from "react";
import { ProductItem } from "../../../components/blocks/ProductRail/ProductItemType";
import { ButtonPcs } from "../../ButtonPcs/ButtonPcs";

const cx = classNames.bind(styles);

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const productImageReferenceWidth = 310;

type ProductCardInfo = {
  title: TeaserBasicProps["title"];
  kicker: TeaserBasicProps["kicker"];
  text: TeaserBasicProps["text"];
  graphicUrl: string;
  graphicAlt?: string;
  name: string;
  textColor?: string;
  productUrl: string;
  buttonLabelColor?: string;
  buttonBackgroundColor?: string;
  backgroundColor?: string;
  cta?: string;
  colorSchemeKey?: string;
  linkUrl?: string;
  linkLabel?: string;
};

export const ProductStageCards = ({
  cards,
  productRail,
  editionsCard,
}: {
  cards: ProductCardInfo[];
  productRail: React.ComponentProps<typeof ProductRail>;
  editionsCard?: ProductCardInfo /* TODO shouldn't be optional */;
}) => {
  const imageSource = cards[0].graphicUrl
    ? getImageSource(cards[0].graphicUrl)
    : null;
  const imageOneDimensions = getStoryblokImageDimension(cards[0].graphicUrl);
  const imageTwoDimensions = getStoryblokImageDimension(cards[1].graphicUrl);
  const imageThreeDimensions = getStoryblokImageDimension(cards[2].graphicUrl);

  const imageLoading = useImageLoading();

  const [productRailSelected, setProductRailSelected] = useState<
    ProductItem | undefined
  >(undefined);

  const translations = useTranslationsContext();

  const pinRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const oneRef = useRef<HTMLDivElement | null>(null);
  const twoRef = useRef<HTMLDivElement | null>(null);
  // const twoOverlayRef = useRef<HTMLDivElement | null>(null);
  const threeRef = useRef<HTMLDivElement | null>(null);
  // const threeOverlayRef = useRef<HTMLDivElement | null>(null);
  const fourRef = useRef<HTMLDivElement | null>(null);
  // const fourOverlayRef = useRef<HTMLDivElement | null>(null);
  const fourRailRef = useRef<HTMLDivElement | null>(null);
  const imageOneRef = useRef<HTMLImageElement | null>(null);
  const oneInnerRef = useRef<HTMLDivElement | null>(null);
  const oneTextRef = useRef<HTMLDivElement | null>(null);
  const contentOneRef = useRef<HTMLDivElement | null>(null);
  const oneMobileOffsetRef = useRef<HTMLDivElement | null>(null);
  const oneMobileOverlayRef = useRef<HTMLDivElement | null>(null);
  const oneMobileOverlay1Ref = useRef<HTMLDivElement | null>(null);
  const oneMobileOverlay2Ref = useRef<HTMLDivElement | null>(null);
  const oneMobileOverlay3Ref = useRef<HTMLDivElement | null>(null);

  const twoImageRef = useRef<HTMLImageElement | null>(null);
  const threeImageRef = useRef<HTMLImageElement | null>(null);

  const twoContentWrapRef = useRef<HTMLDivElement | null>(null);
  const threeContentWrapRef = useRef<HTMLDivElement | null>(null);

  // const testRef = useRef<HTMLDivElement | null>(null);

  const colorScheme1 = getSchemeById(cards[0].colorSchemeKey);
  // const colorSchemeTextAppearance1 = colorScheme1.textAppearance ?? "dark";

  /* 
    The original can uses a white background here, which is not part of its color scheme.
    Hence it's hard-coded here.
  */
  const colorSchemePrimary1 =
    cards[0].colorSchemeKey === "energy-drink-original"
      ? "--cosmos-color-surface-solid-light-lighter"
      : colorScheme1?.secondary ?? colorScheme1?.primary;
  const colorSchemeSecondary1 = colorScheme1.secondary;
  const colorCanShadowRGB1 = colorScheme1.canShadowRGB;

  const colorScheme2 = getSchemeById(cards[1].colorSchemeKey);
  const colorSchemeTextAppearance2 = colorScheme2.textAppearance ?? "dark";
  const colorSchemePrimary2 = colorScheme2?.primary ?? colorScheme2?.secondary;
  const colorSchemeSecondary2 =
    colorScheme2?.secondary ?? colorScheme2?.primary;
  const colorCanShadowRGB2 = colorScheme2.canShadowRGB;

  const colorScheme3 = getSchemeById(cards[2].colorSchemeKey);
  const colorSchemeTextAppearance3 = colorScheme3.textAppearance ?? "dark";
  const colorSchemePrimary3 = colorScheme3?.primary ?? colorScheme3?.secondary;
  const colorSchemeSecondary3 =
    colorScheme3?.secondary ?? colorScheme3?.primary;
  const colorCanShadowRGB3 = colorScheme3.canShadowRGB;

  const colorScheme4 = getSchemeById(
    productRailSelected?.colorSchemeKey ??
      productRail.productItems?.[0].colorSchemeKey,
  );
  // const colorSchemeTextAppearance4 = colorScheme4.textAppearance ?? "dark";
  const colorSchemePrimary4 = colorScheme4?.primary ?? colorScheme4?.secondary;
  const colorSchemeSecondary4 =
    colorScheme4?.secondary ?? colorScheme4?.primary;
  const colorCanShadowRGB4 = colorScheme4.canShadowRGB;

  const cardsWithEdittionCard: ProductCardInfo[] = editionsCard
    ? cards.concat(editionsCard)
    : cards;

  // useEffect(() => {
  //   if (!containerRef.current) return;

  //   // Create a div to measure 100svh
  //   const div = document.createElement("div");
  //   div.style.position = "absolute";
  //   div.style.height = "100svh";
  //   div.style.width = "1px";
  //   div.style.visibility = "hidden";

  //   containerRef.current.appendChild(div);
  //   setSvhHeight(div.clientHeight);
  //   containerRef.current.removeChild(div); // Cleanup
  // }, []);

  useGSAP(() => {
    const mm = gsap.matchMedia();

    mm.add("(width < 1000px)", (context) => {
      // 88px is the height of the 3 overlay bars in mobile
      // 104 is that +16px
      // 100 is some margin at the bottom of each element to make it stick slightly

      // const viewport = window.visualViewport
      //   ? window.visualViewport.height
      //   : window.innerHeight;
      // const offsetY = viewport - (oneRef.current?.offsetHeight ?? 0);

      /* Stops jittery/jumpy in Safari: */
      // ScrollTrigger.normalizeScroll({ allowNestedScroll: true });

      /* Get the LVH and SVH units */
      // const div = document.createElement("div");
      // div.style.position = "absolute";
      // div.style.height = "100svh";
      // div.style.width = "1px";
      // div.style.visibility = "hidden";
      // if (containerRef.current) {
      //   containerRef.current.appendChild(div);
      // }
      // // setSvhHeight(div.clientHeight);
      // const svhHeight1 = div.clientHeight;
      // div.style.height = "100lvh";
      // const windowHeight = div.clientHeight;
      // if (containerRef.current) {
      //   containerRef.current.removeChild(div);
      // }
      // const svhOffset = windowHeight - svhHeight1;
      // console.log("svhHeight1", svhHeight1);
      // console.log("windowHeight", windowHeight);
      // console.log("svhOffset", svhOffset);

      // gsap.set(imageOneRef.current, {
      //   attr: { "mobile-visible": "true" },
      //   delay: 0.75,
      // });

      // const mobileStartTimeline = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: oneMobileOffsetRef.current,
      //     start: "top top",
      //     end: `top top-=${104 + svhOffset}`,
      //     pin: true,
      //     pinSpacing: false,
      //     scrub: true,
      //     markers: false,
      //     invalidateOnRefresh: true,
      //     anticipatePin: 1,
      //   },
      // });

      // mobileStartTimeline
      //   .to(oneRef.current, {
      //     width: "100vw",
      //     ease: "none",
      //     attr: { "clip-path": "true" },
      //     onUpdate: function () {
      //       const progress = this.progress(); // Get animation progress (0 to 1)
      //       // const newOffset = 88 - 104 * progress; // Interpolate -88px to 16px
      //       const newOffset = 88 - (104 + svhOffset) * progress; // Interpolate -88px to 16px
      //       oneRef.current?.style.setProperty(
      //         "--_clip-offset",
      //         `${newOffset}px`,
      //       );
      //     },
      //   })
      //   .to(
      //     oneMobileOverlayRef.current,
      //     {
      //       y: `+=${104 + svhOffset}`,
      //       ease: "none",
      //     },
      //     "<",
      //   )
      //   .to(
      //     oneMobileOverlay1Ref.current,
      //     {
      //       width: "calc(100vw - 32px)",
      //       ease: "none",
      //     },
      //     "<",
      //   )
      //   .to(
      //     oneMobileOverlay2Ref.current,
      //     {
      //       width: "calc(100vw - 64px)",
      //       ease: "none",
      //     },
      //     "<",
      //   )
      //   .to(
      //     oneMobileOverlay3Ref.current,
      //     {
      //       width: "calc(100vw - 96px)",
      //       ease: "none",
      //     },
      //     "<",
      //   )
      //   .set(oneRef.current, {
      //     height: "auto",
      //     borderBottomLeftRadius: 0,
      //     borderBottomRightRadius: 0,
      //     attr: { "clip-path": "false" },
      //   })
      //   .set(oneInnerRef.current, { y: 0 }, "<")
      //   .set(oneMobileOverlayRef.current, { display: "none" }, "<");

      ScrollTrigger.create({
        trigger: oneMobileOffsetRef.current,
        start: "bottom bottom+=300",
        end: "bottom -=200",
        pin: true,
        markers: false,
        pinSpacing: false,
        invalidateOnRefresh: true,
        anticipatePin: 1,
      });

      gsap.to(twoRef.current, {
        ease: "none",
        // attr: { "mobile-locked": "true" },
        scrollTrigger: {
          trigger: twoRef.current,
          start: "bottom bottom",
          end: "bottom -100",
          pin: true,
          markers: false,
          pinSpacing: false,
          invalidateOnRefresh: true,
          anticipatePin: 1,
          onEnter: () => twoRef.current?.setAttribute("mobile-active", "true"),
          onLeaveBack: () => twoRef.current?.removeAttribute("mobile-active"),
        },
      });

      // gsap.to(twoImageRef.current, {
      //   attr: { "mobile-visible": "true" },
      //   scrollTrigger: {
      //     trigger: twoRef.current,
      //     start: "top 66%",
      //     invalidateOnRefresh: true,
      //   },
      // });

      gsap.to(threeRef.current, {
        ease: "none",
        // attr: { "mobile-locked": "true" },
        scrollTrigger: {
          trigger: threeRef.current,
          start: "bottom bottom",
          end: "bottom -100",
          // scrub: true,
          pin: true,
          markers: false,
          pinSpacing: false,
          invalidateOnRefresh: true,
          anticipatePin: 1,
          onEnter: () =>
            threeRef.current?.setAttribute("mobile-active", "true"),
          onLeaveBack: () => threeRef.current?.removeAttribute("mobile-active"),
        },
      });

      // gsap.to(threeImageRef.current, {
      //   attr: { "mobile-visible": "true" },
      //   scrollTrigger: {
      //     trigger: threeRef.current,
      //     start: "top 66%",
      //     invalidateOnRefresh: true,
      //   },
      // });

      gsap.to(fourRef.current, {
        ease: "none",
        scrollTrigger: {
          trigger: fourRef.current,
          start: "bottom bottom",
          end: "bottom bottom-=100",
          pin: true,
          markers: false,
          pinSpacing: false,
          invalidateOnRefresh: true,
          anticipatePin: 1,
          onEnter: () => fourRef.current?.setAttribute("mobile-active", "true"),
          onLeaveBack: () => fourRef.current?.removeAttribute("mobile-active"),
        },
      });

      return () => {
        // mobileStartTimeline.revert();
        context.revert(); // Clears transforms and ScrollTrigger
      };
    });

    // mm.add("(width < 1000px)", () => {
    //   //     gsap.to(testRef.current, {
    //   //   scrollTrigger: {
    //   //     trigger: testRef.current,
    //   //     start: "bottom bottom",
    //   //     end: "bottom top",
    //   //     pin: true,
    //   //     pinSpacing: false,
    //   //     markers: true,
    //   //     pinType: "transform",
    //   //   }
    //   // });

    //   // ScrollTrigger.create({
    //   //       markers: true,
    //   //       id: 'pin1',
    //   //       // pin: true,
    //   //       trigger: oneInnerRef.current,
    //   //       start: "top top",
    //   //       end: 'bottom botom',
    //   //   })

    //   // ScrollTrigger.create({
    //   //       markers: true,
    //   //       id: 'pin1',
    //   //       pin: true,
    //   //       trigger: oneRef.current,
    //   //       start: "top top",
    //   //       end: '+=200',
    //   //   })

    //   //   ScrollTrigger.create({
    //   //       markers: {startColor: "green", endColor: "red", fontSize: "10px", fontWeight: "normal", indent: 500},
    //   //       id: 'pin2',
    //   //       pin: true,
    //   //       trigger: testRef.current,
    //   //       start: "top center",
    //   //       end: '+=1000',
    //   //       pinnedContainer: oneRef.current,
    //   //       pinType: "transform",
    //   //   })

    //   //   ScrollTrigger.create({
    //   //   trigger: oneRef.current,
    //   //   start: "top top",
    //   //   end: "bottom bottom",
    //   //   onUpdate: (self) => {
    //   //     if (self.progress === 1) {
    //   //       testRef.current?.setAttribute('data-anchored', "true");
    //   //     } else {
    //   //       testRef.current?.removeAttribute('data-anchored');
    //   //     }
    //   //   },
    //   //   markers: false
    //   // });

    //   // const mobileOneTextTimeline = gsap.timeline({
    //   //   scrollTrigger: {
    //   //     trigger: oneTextRef.current,
    //   //     start: "bottom bottom",
    //   //     // end: "bottom bottom",
    //   //     //pin: true,
    //   //     //pinSpacing: false,
    //   //     // scrub: true,
    //   //     markers: true,
    //   //     //pinType: "fixed"
    //   //   },
    //   // });

    //   // mobileOneTextTimeline.fromTo(
    //   //   oneTextRef.current,
    //   //   {
    //   //     y: "100%",
    //   //   },
    //   //   {
    //   //     y: 0,
    //   //   },
    //   //   "<",
    //   // );

    //   const mobileMainTimeline = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: containerRef.current,
    //       start: "top top",
    //       end: "bottom bottom",
    //       //pin: true,
    //       //pinSpacing: false,
    //       scrub: true,
    //       markers: false,
    //       //pinType: "fixed"
    //     },
    //   });

    //   mobileMainTimeline
    //     .set(imageOneRef.current, {
    //       translateY: 0, // reset when switching breakpoints
    //     })
    //     .set(oneRef.current, {
    //       width: "calc(100vw - 32px)", // reset when switching breakpoints
    //     })
    //     .set(twoOverlayRef.current, {
    //       inert: true,
    //       attr: { "data-visibility": "visible" },
    //     })
    //     .set(threeOverlayRef.current, {
    //       inert: true,
    //       attr: { "data-visibility": "visible" },
    //     })
    //     .set(fourOverlayRef.current, {
    //       inert: true,
    //       attr: { "data-visibility": "visible" },
    //     })
    //     .to(oneRef.current, {
    //       width: "100vw",
    //       height: "100vh",
    //       duration: 0.25,
    //     })
    //     .to(
    //       twoRef.current,
    //       {
    //         width: "100vw",
    //         height: "calc(100vh + 90px)",
    //         duration: 0.25,
    //       },
    //       "<",
    //     )
    //     .to(
    //       threeRef.current,
    //       {
    //         width: "100vw",
    //         height: "calc(100vh + 180px)",
    //         duration: 0.25,
    //       },
    //       "<",
    //     )
    //     .to(
    //       fourRef.current,
    //       {
    //         width: "100vw",
    //         height: "calc(100vh + 270px)",
    //         duration: 0.25,
    //       },
    //       "<",
    //     )

    //     .set(twoOverlayRef.current, {
    //       inert: true,
    //       attr: { "data-visibility": "hidden" },
    //     })
    //     .set(threeOverlayRef.current, {
    //       inert: true,
    //       attr: { "data-visibility": "hidden" },
    //     })
    //     .set(fourOverlayRef.current, {
    //       inert: true,
    //       attr: { "data-visibility": "hidden" },
    //     })
    //     .set(twoContentWrapRef.current, {
    //       attr: { "data-visibility": "visible" },
    //     })
    //     .set(threeContentWrapRef.current, {
    //       attr: { "data-visibility": "visible" },
    //     })
    //     .set(fourRailRef.current, {
    //       attr: { "data-mobile-visibility": "visible" },
    //     })
    //     .set(oneRef.current, {
    //       height: "auto",
    //     })
    //     .set(twoRef.current, {
    //       height: "auto",
    //       minHeight: "100vh",
    //     })
    //     .set(threeRef.current, {
    //       height: "auto",
    //       minHeight: "100vh",
    //     })
    //     .set(fourRef.current, {
    //       height: "auto",
    //       minHeight: "100vh",
    //     })

    //     .to(oneRef.current, {
    //       y: () => {
    //         // Calculate the distance from the card's bottom to viewport bottom
    //         if (!oneInnerRef.current) {
    //           return 0;
    //         }

    //         const offset =
    //           window.innerHeight -
    //           oneInnerRef.current.getBoundingClientRect().bottom;

    //         return offset < 0 ? offset : 0; // Only move if it's below viewport bottom
    //       },
    //       // duration: 0.25,
    //       duration: () => {
    //         if (!oneInnerRef.current) {
    //           return 0;
    //         }
    //         // const offset =
    //         //   window.innerHeight -
    //         //   oneInnerRef.current.getBoundingClientRect().bottom;
    //         return 0.25;
    //         // return offset < 0 ? 0.25 : 0; // Skip if already aligned
    //       },
    //     })
    //     .to({}, { duration: 0.25 })
    //     .to(oneRef.current, {
    //       y: "-100%",
    //       duration: 1,
    //       attr: { "data-no-shadow": "false" },
    //     })
    //     .set(oneRef.current, {
    //       attr: { "data-no-shadow": "true" },
    //     })
    //     .to({}, { duration: 0.25 })
    //     .to(twoRef.current, {
    //       y: "-100%",
    //       duration: 1,
    //       attr: { "data-no-shadow": "false" },
    //     })
    //     .set(twoRef.current, {
    //       attr: { "data-no-shadow": "true" },
    //     })
    //     .to({}, { duration: 0.25 })
    //     .to(threeRef.current, {
    //       y: "-100%",
    //       duration: 1,
    //       attr: { "data-no-shadow": "false" },
    //     })
    //     .set(threeRef.current, {
    //       attr: { "data-no-shadow": "true" },
    //     })
    //     .to({}, { duration: 1 });
    // });

    mm.add("(width >= 1000px)", (context) => {
      const mainTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top top",
          end: "bottom bottom",
          scrub: true,
          markers: false,
          invalidateOnRefresh: true,
        },
      });

      mainTimeline
        // .fromTo(
        //   oneRef.current,
        //   {
        //     x: -200 * 3,
        //   },
        //   {
        //     x: -58 * 3,
        //   },
        //   "<",
        // )
        // .fromTo(
        //   imageOneRef.current,
        //   {
        //     x: 372,
        //     y: "25%",
        //   },
        //   {
        //     // inert: true,
        //     // width: 261,
        //     scale: 261 / 310,
        //     x: 87,
        //     y: 0,
        //   },
        //   "<",
        // )
        // .fromTo(
        //   contentOneRef.current,
        //   {
        //     x: 144,
        //   },
        //   {
        //     x: 0,
        //   },
        //   "<",
        // )
        // .fromTo(
        //   twoRef.current,
        //   {
        //     x: -200 * 2,
        //     // x: 0
        //   },
        //   {
        //     x: -58 * 2,
        //   },
        //   "<",
        // )
        // // .to(
        // //   twoOverlayRef.current,
        // //   {
        // //     // inert: true,
        // //     opacity: 1,
        // //   },
        // //   "<",
        // // )
        // .fromTo(
        //   threeRef.current,
        //   {
        //     x: -200,
        //     // x: 0
        //   },
        //   {
        //     x: -58,
        //   },
        //   "<",
        // )
        // // .to(
        // //   threeOverlayRef.current,
        // //   {
        // //     // inert: true,
        // //     opacity: 1,
        // //   },
        // //   "<",
        // // )
        // // .to(
        // //   fourOverlayRef.current,
        // //   {
        // //     // inert: true,
        // //     opacity: 1,
        // //   },
        // //   "<",
        // // )
        // .set(
        //   twoOverlayRef.current,
        //   {
        //     inert: true,
        //     // opacity: 0,
        //   },
        //   "<50%",
        // )
        // .set(
        //   threeOverlayRef.current,
        //   {
        //     inert: true,
        //     // opacity: 0,
        //   },
        //   "<50%",
        // )
        // .set(
        //   fourOverlayRef.current,
        //   {
        //     inert: true,
        //     // opacity: 0,
        //   },
        //   "<50%",
        // )
        .set(twoContentWrapRef.current, {
          attr: { "data-visibility": "visible" },
        })
        .set(threeContentWrapRef.current, {
          attr: { "data-visibility": "visible" },
        })
        .to(
          oneMobileOffsetRef.current,
          {
            x: "-100%",
            opacity: 1,
            delay: 0.25,
            attr: { "data-no-shadow": "false" },
          },
          ">",
        )
        .set(
          oneRef.current,
          {
            attr: { "data-no-shadow": "false" },
          },
          "<",
        )
        .set(oneRef.current, {
          attr: { "data-no-shadow": "true" },
        })
        .to(
          twoRef.current,
          {
            x: "-100%",
            opacity: 1,
            delay: 0.25,
            attr: { "data-no-shadow": "false" },
          },
          ">",
        )
        .set(twoRef.current, {
          attr: { "data-no-shadow": "true" },
        })
        .to(
          threeRef.current,
          {
            x: "-100%",
            opacity: 1,
            delay: 0.25,
            attr: { "data-no-shadow": "false" },
          },
          ">",
        )
        .to(
          fourRef.current,
          {
            x: "-100%",
            opacity: 1,
            delay: 0,
          },
          "<",
        )
        .set(threeRef.current, {
          attr: { "data-no-shadow": "true" },
        })
        .to({}, { duration: 0.75 });

      return () => {
        mainTimeline.revert();
        context.revert(); // Clears transforms and ScrollTrigger
      };
    });

    // const handleResize = () => {
    //   ScrollTrigger.refresh();
    // };

    // window.addEventListener("resize", handleResize);

    // const bodyResizeObserver = new ResizeObserver(() => ScrollTrigger.refresh());

    // bodyResizeObserver.observe(document.body);

    // const bodyResizeObserver = new ResizeObserver(() => ScrollTrigger.refresh());

    // bodyResizeObserver.observe(document.body);

    // return () => bodyResizeObserver.disconnect();

    // const imagesLoaded = () => {
    //   ScrollTrigger.refresh(); // Recalculate triggers after images load
    // };

    // window.addEventListener("load", imagesLoaded);
    // return () => window.removeEventListener("load", imagesLoaded);
    return () => {
      // window.removeEventListener("resize", handleResize);
      mm.revert(); // Revert all media query listeners and their associated animations
      // bodyResizeObserver.disconnect();
    };
  }, []);

  return (
    <ProductRailContext.Provider
      value={{ selectedProductRailItem: setProductRailSelected }}
    >
      <div
        className={cx("container")}
        ref={containerRef}
        data-product-drop-shadows="true"
        data-rounded-corners="true"
        data-shadows="true"
      >
        {/*<div className={cx("scroller")}>
          <div className={cx("scroller-inner")}></div>
        </div>*/}
        <div className={cx("inner-pin")} ref={pinRef}>
          <div className={cx("inner")}>
            <div className={cx("one-mobile-offset")} ref={oneMobileOffsetRef}>
              <div
                className={cx("one")}
                ref={oneRef}
                style={{
                  "--scheme-primary": colorSchemePrimary1,
                  "--scheme-secondary": colorSchemeSecondary1,
                  "--scheme-can-shadow-rgb": colorCanShadowRGB1,
                }}
              >
                <div className={cx("shadow", "shadow1")}></div>
                <div className={cx("one-inner")} ref={oneInnerRef}>
                  <div className={cx("content-wrap-one")}>
                    <div className={cx("content-one")} ref={contentOneRef}>
                      <TeaserBasic
                        className={cx("teaser-basic")}
                        kicker={cardsWithEdittionCard[0].kicker}
                        title={cardsWithEdittionCard[0].title}
                        titleClassName={cx("one-title")}
                        titleSize="x-large"
                        text={cardsWithEdittionCard[0].text}
                        textClassName={cx("teaser-text1-desktop")}
                        appearance={"dark"}
                        headingElement="kicker"
                        headingTag="h1"
                      >
                        <div className={cx("desktop-cta-container")}>
                          <ButtonWrapper>
                            <ButtonPcs
                              size="large"
                              href={cardsWithEdittionCard[0].productUrl}
                              customColor={colorScheme1.customButtonColor}
                            >
                              {template(
                                translations["productStageCards.seeProduct"],
                                "See product",
                              )}
                            </ButtonPcs>
                          </ButtonWrapper>
                        </div>
                      </TeaserBasic>
                    </div>
                    <div className={cx("image-container-one")}>
                      {cardsWithEdittionCard[0].graphicUrl &&
                        (() => {
                          const imageProps = {
                            ref: imageOneRef,
                            loading: imageLoading,
                            className: cx("image-one"),
                            alt: cardsWithEdittionCard[0].graphicAlt ?? "",
                            width: imageOneDimensions?.width,
                            height: imageOneDimensions?.height,
                          };
                          switch (imageSource) {
                            case "contentful": {
                              return (
                                <img
                                  {...imageProps}
                                  mobile-visible="false"
                                  src={getContentfulImageUrl(
                                    cardsWithEdittionCard[0].graphicUrl,
                                    {
                                      width: productImageReferenceWidth,
                                      height: undefined,
                                    },
                                  )}
                                />
                              );
                            }
                            case "storyblok": {
                              return (
                                <img
                                  {...imageProps}
                                  mobile-visible="false"
                                  src={getStoryblokImageUrl(
                                    cardsWithEdittionCard[0].graphicUrl,
                                    {
                                      width: productImageReferenceWidth,
                                      height: undefined,
                                    },
                                  )}
                                />
                              );
                            }
                            default: {
                              return (
                                <img
                                  {...imageProps}
                                  mobile-visible="false"
                                  src={cardsWithEdittionCard[0].graphicUrl}
                                />
                              );
                            }
                          }
                        })()}

                      {/* {cardsWithEdittionCard[0].graphicUrl &&
                    (imageSource === "contentful" ? (
                      <img
                        ref={imageOneRef}
                        className={cx("image-one")}
                        loading={imageLoading}
                        src={getContentfulImageUrl(
                          cardsWithEdittionCard[0].graphicUrl,
                          {
                            width: productImageReferenceWidth;,
                            height: undefined,
                          },
                        )}
                        alt=""
                      />
                    ) : imageSource === "storyblok" ? (
                      <img
                        ref={imageOneRef}
                        className={cx("image-one")}
                        loading={imageLoading}
                        src={getStoryblokImageUrl(
                          cardsWithEdittionCard[0].graphicUrl,
                          {
                            width: productImageReferenceWidth,
                            height: undefined,
                          },
                        )}
                        alt=""
                      />
                    ) : (
                      <img
                        ref={imageOneRef}
                        className={cx("image-one")}
                        loading={imageLoading}
                        src={cardsWithEdittionCard[0].graphicUrl}
                        alt=""
                      />
                    ))}*/}
                    </div>

                    <div className={cx("teaser-text1-mobile")} ref={oneTextRef}>
                      <TeaserBasic
                        titleSize="x-large"
                        headingElement="title"
                        appearance="dark"
                      >
                        {/* 
                      TODO: this sometimes fails to "small" on desktop? 
                      Error: Text content does not match server-rendered HTML.
                    */}

                        <CosmosText size="medium">
                          {cardsWithEdittionCard[0].text}
                        </CosmosText>
                      </TeaserBasic>
                    </div>

                    <div className={cx("sticky-button-wrapper-one")}>
                      <div
                        className={cx("sticky-button-wrapper-inner")}
                        // ref={stickyButtonRef}
                      >
                        <ButtonWrapper>
                          <ButtonPcs
                            size="large"
                            href={cardsWithEdittionCard[0].productUrl}
                            customColor={colorScheme1.customButtonColor}
                          >
                            {template(
                              translations["productStageCards.seeProduct"],
                              "See product",
                            )}
                          </ButtonPcs>
                        </ButtonWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cx("one-mobile-overlay")}
                ref={oneMobileOverlayRef}
              >
                <div className={cx("one-mobile-overlay-inner")}>
                  <div
                    className={cx("one-mobile-overlay1")}
                    ref={oneMobileOverlay1Ref}
                    style={{ "--scheme-secondary": colorSchemeSecondary2 }}
                  ></div>
                  <div
                    className={cx("one-mobile-overlay2")}
                    ref={oneMobileOverlay2Ref}
                    style={{ "--scheme-secondary": colorSchemeSecondary3 }}
                  ></div>
                  <div
                    className={cx("one-mobile-overlay3")}
                    ref={oneMobileOverlay3Ref}
                    style={{ "--scheme-secondary": colorSchemeSecondary4 }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              className={cx("two")}
              ref={twoRef}
              style={{
                "--scheme-primary": colorSchemePrimary2,
                "--scheme-secondary": colorSchemeSecondary2,
                "--scheme-can-shadow-rgb": colorCanShadowRGB2,
              }}
            >
              <div className={cx("shadow", "shadow2")}></div>
              <div className={cx("two-inner")}>
                {/*<div className={cx("two-overlay")} ref={twoOverlayRef}>
                  <ProductStageCardOverlay
                    productName={cardsWithEdittionCard[1].title ?? ""}
                    url={
                      cardsWithEdittionCard[1].linkUrl ??
                      cardsWithEdittionCard[1].productUrl
                    }
                    className={undefined}
                    ctaText={
                      cardsWithEdittionCard[1].linkLabel ??
                      template(
                        translations["productStageCards.seeProduct"],
                        "See product",
                      )
                    }
                    graphicUrl={cardsWithEdittionCard[1].graphicUrl}
                    colorSchemeKey={cardsWithEdittionCard[1].colorSchemeKey}
                    textAppearanceOverwrite="light"
                  />
                </div>*/}
                <div
                  className={cx("content-wrap")}
                  ref={twoContentWrapRef}
                  data-visibility="hidden"
                >
                  <div className={cx("content")}>
                    <TeaserBasic
                      className={cx("teaser-basic")}
                      kicker={cardsWithEdittionCard[1].kicker}
                      title={cardsWithEdittionCard[1].title}
                      titleSize="x-large"
                      text={cardsWithEdittionCard[1].text}
                      textClassName={cx("teaser-text")}
                      appearance={colorSchemeTextAppearance2}
                      headingElement="title"
                    >
                      <div className={cx("desktop-cta-container")}>
                        <ButtonWrapper>
                          <ButtonPcs
                            size="large"
                            href={
                              cardsWithEdittionCard[1].linkUrl ??
                              cardsWithEdittionCard[1].productUrl
                            }
                            customColor={colorScheme2.customButtonColor}
                          >
                            {cardsWithEdittionCard[1].linkLabel ||
                              template(
                                translations["productStageCards.seeProduct"],
                                "See product",
                              )}
                          </ButtonPcs>
                        </ButtonWrapper>
                      </div>
                    </TeaserBasic>
                  </div>
                  <div className={cx("image-container")}>
                    {cardsWithEdittionCard[1].graphicUrl &&
                      (() => {
                        const imageProps = {
                          loading: imageLoading,
                          className: cx("image"),
                          alt: cardsWithEdittionCard[1].graphicAlt ?? "",
                          ref: twoImageRef,
                          height: imageTwoDimensions?.height,
                          width: imageTwoDimensions?.width,
                        };

                        switch (imageSource) {
                          case "contentful": {
                            return (
                              <img
                                {...imageProps}
                                mobile-visible="false"
                                src={getContentfulImageUrl(
                                  cardsWithEdittionCard[1].graphicUrl,
                                  {
                                    width: productImageReferenceWidth,
                                    height: undefined,
                                  },
                                )}
                              />
                            );
                          }
                          case "storyblok": {
                            return (
                              <img
                                {...imageProps}
                                mobile-visible="false"
                                src={getStoryblokImageUrl(
                                  cardsWithEdittionCard[1].graphicUrl,
                                  {
                                    width: productImageReferenceWidth,
                                    height: undefined,
                                  },
                                )}
                              />
                            );
                          }
                          default: {
                            return (
                              <img
                                {...imageProps}
                                mobile-visible="false"
                                src={cardsWithEdittionCard[1].graphicUrl}
                              />
                            );
                          }
                        }
                      })()}
                  </div>
                  <div className={cx("sticky-button-wrapper")}>
                    <div
                      className={cx("sticky-button-wrapper-inner")}
                      // ref={stickyButtonRef}
                    >
                      <ButtonWrapper>
                        <ButtonPcs
                          size="large"
                          href={
                            cardsWithEdittionCard[1].linkUrl ??
                            cardsWithEdittionCard[1].productUrl
                          }
                          customColor={colorScheme2.customButtonColor}
                        >
                          {cardsWithEdittionCard[1].linkLabel ||
                            template(
                              translations["productStageCards.seeProduct"],
                              "See product",
                            )}
                        </ButtonPcs>
                      </ButtonWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={cx("three")}
              ref={threeRef}
              style={{
                "--scheme-primary": colorSchemePrimary3,
                "--scheme-secondary": colorSchemeSecondary3,
                "--scheme-can-shadow-rgb": colorCanShadowRGB3,
              }}
            >
              <div className={cx("shadow", "shadow3")}></div>
              <div className={cx("three-inner")}>
                {/*<div className={cx("three-overlay")} ref={threeOverlayRef}>
                  <ProductStageCardOverlay
                    productName={cardsWithEdittionCard[2].title ?? ""}
                    url={
                      cardsWithEdittionCard[2].linkUrl ??
                      cardsWithEdittionCard[2].productUrl
                    }
                    className={undefined}
                    ctaText={
                      cardsWithEdittionCard[2].linkLabel ||
                      template(
                        translations["productStageCards.seeProduct"],
                        "See product",
                      )
                    }
                    graphicUrl={cardsWithEdittionCard[2].graphicUrl}
                    colorSchemeKey={cardsWithEdittionCard[2].colorSchemeKey}
                  />
                </div>*/}
                <div
                  className={cx("content-wrap")}
                  ref={threeContentWrapRef}
                  data-visibility="hidden"
                >
                  <div className={cx("content")}>
                    <TeaserBasic
                      className={cx("teaser-basic")}
                      kicker={cardsWithEdittionCard[2].kicker}
                      title={cardsWithEdittionCard[2].title}
                      titleSize="x-large"
                      text={cardsWithEdittionCard[2].text}
                      textClassName={cx("teaser-text")}
                      appearance={colorSchemeTextAppearance3}
                      headingElement="title"
                    >
                      <div className={cx("desktop-cta-container")}>
                        <ButtonWrapper>
                          <ButtonPcs
                            size="large"
                            href={
                              cardsWithEdittionCard[2].linkUrl ??
                              cardsWithEdittionCard[2].productUrl
                            }
                            customColor={colorScheme3.customButtonColor}
                          >
                            {cardsWithEdittionCard[2].linkLabel ||
                              template(
                                translations["productStageCards.seeProduct"],
                                "See product",
                              )}
                          </ButtonPcs>
                        </ButtonWrapper>
                      </div>
                    </TeaserBasic>
                  </div>
                  <div className={cx("image-container")}>
                    {cardsWithEdittionCard[2].graphicUrl &&
                      (() => {
                        const imageProps = {
                          loading: imageLoading,
                          className: cx("image"),
                          alt: cardsWithEdittionCard[2].graphicAlt ?? "",
                          ref: threeImageRef,
                          height: imageThreeDimensions?.height,
                          width: imageThreeDimensions?.width,
                        };

                        switch (imageSource) {
                          case "contentful": {
                            return (
                              <img
                                {...imageProps}
                                mobile-visible="false"
                                src={getContentfulImageUrl(
                                  cardsWithEdittionCard[2].graphicUrl,
                                  {
                                    width: productImageReferenceWidth,
                                    height: undefined,
                                  },
                                )}
                              />
                            );
                          }
                          case "storyblok": {
                            return (
                              <img
                                {...imageProps}
                                mobile-visible="false"
                                src={getStoryblokImageUrl(
                                  cardsWithEdittionCard[2].graphicUrl,
                                  {
                                    width: productImageReferenceWidth,
                                    height: undefined,
                                  },
                                )}
                              />
                            );
                          }
                          default: {
                            return (
                              <img
                                {...imageProps}
                                mobile-visible="false"
                                src={cardsWithEdittionCard[2].graphicUrl}
                              />
                            );
                          }
                        }
                      })()}
                  </div>
                  <div className={cx("sticky-button-wrapper")}>
                    <div
                      className={cx("sticky-button-wrapper-inner")}
                      // ref={stickyButtonRef}
                    >
                      <ButtonWrapper>
                        <ButtonPcs
                          size="large"
                          href={
                            cardsWithEdittionCard[2].linkUrl ??
                            cardsWithEdittionCard[2].productUrl
                          }
                          customColor={colorScheme3.customButtonColor}
                        >
                          {cardsWithEdittionCard[2].linkLabel ||
                            template(
                              translations["productStageCards.seeProduct"],
                              "See product",
                            )}
                        </ButtonPcs>
                      </ButtonWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={cx("four")}
              ref={fourRef}
              style={{
                "--scheme-primary": colorSchemePrimary4,
                "--scheme-secondary": colorSchemeSecondary4,
                "--scheme-can-shadow-rgb": colorCanShadowRGB4,
              }}
            >
              <div className={cx("four-inner")}>
                {/*<div className={cx("four-overlay")} ref={fourOverlayRef}>
                  <ProductStageCardOverlay
                    productName={template(
                      translations["productStageCards.seeEditionsProductName"],
                      "Red Bull Editions",
                    )}
                    url={
                      cardsWithEdittionCard[3]?.linkUrl ??
                      productRailSelected?.url ??
                      cardsWithEdittionCard[3]?.productUrl
                    }
                    className={undefined}
                    ctaText={template(
                      translations["productStageCards.seeEditions"],
                      "See products",
                    )}
                    graphicUrl={
                      productRailSelected?.productImageUrl ??
                      cardsWithEdittionCard[3]?.graphicUrl
                    }
                    colorSchemeKey={
                      productRailSelected?.colorSchemeKey ??
                      cardsWithEdittionCard[3]?.colorSchemeKey
                    }
                  />
                </div>*/}
              </div>
              <div
                className={cx("four-rail")}
                ref={fourRailRef}
                data-mobile-visibility="hidden"
              >
                {/*<div className={cx("product-rail")}>*/}

                <ProductRail
                  className={cx("product-rail")}
                  kicker={productRail.kicker}
                  // title={productRail.title}
                  text={productRail.text}
                  productItems={productRail.productItems}
                  filter={productRail.filter}
                  animate={true}
                />

                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProductRailContext.Provider>
  );
};
