"use client";

import { CosmosButton } from "@cosmos/web/react";
import { ButtonWrapper } from "../../ButtonWrapper/ButtonWrapper";
import { TeaserBasic } from "../../TeaserBasic/TeaserBasic";
import classNames from "classnames/bind";
import styles from "./OrganicsTeaser.module.css";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";

const cx = classNames.bind(styles);

export function OrganicsTeaser({
  kicker,
  title,
  buttonLabel,
  buttonUrl,
  imageSrc,
  imageSrcSet,
  imageAlt = title,
}: {
  kicker: string;
  title: string;
  buttonLabel: string;
  buttonUrl: string;
  imageSrc: string;
  imageSrcSet?: HTMLImageElement["srcset"];
  imageAlt?: string;
}) {
  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <TeaserBasic
            className={cx("teaser")}
            kicker={kicker}
            title={title}
            titleSize="xx-large"
            appearance="dark"
            headingElement="kicker"
            children={
              <ButtonWrapper className={cx("button-wrapper")}>
                <CosmosButton size="large" href={buttonUrl}>
                  {buttonLabel}
                </CosmosButton>
              </ButtonWrapper>
            }
          />
        </div>
        <div className={cx("image-wrap")}>
          <img
            className={cx("img")}
            src={imageSrc}
            loading={imageLoading}
            srcSet={imageSrcSet}
            alt={imageAlt}
          />
        </div>
      </div>
    </div>
  );
}
